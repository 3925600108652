<template>
  <div class="navbar" :class="{ 'navbar-hidden': !showNavbar }">
    <nav class="nav-menu">
      <router-link to="/">Product</router-link>
      <router-link to="/about">System</router-link>
      <!-- <router-link to="/connect">Get in Touch</router-link> -->
      <router-link to="/pricing">Cost</router-link>
    </nav>
    <div class="nav-title">
      <router-link to="/" aria-description="Home page link">
       
      </router-link>
    </div>

    <div class="hamburger">
      <font-awesome-icon class="ham-icon" icon="bars" @click="openNav" />
    </div>
    <nav class="port-nav" @click="openNav">
      <router-link to="/">Product</router-link>
      <router-link to="/about">System</router-link>
      <!-- <router-link to="/connect">Get in Touch</router-link> -->
      <router-link to="/pricing">Cost</router-link>
      <!-- <router-link to="/login">Login</router-link> -->
      <button @click.stop.prevent="openNav">Close</button>
    </nav>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      showNavbar: true,
      lastScrollPosition: 0,
      navbarOpen: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
        return;
      }
      this.showNavbar = currentScrollPosition < this.lastScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
    },
    openNav() {
      this.navbarOpen = !this.navbarOpen;
      if (this.navbarOpen) {
        document.querySelector(".port-nav").style.setProperty("display", "flex");
        setTimeout(() => {
          document.querySelector(".port-nav").style.setProperty("transform", "translateX(0)");
        }, 100);
      } else {
        document.querySelector(".port-nav").style.setProperty("transform", "translateX(500px)");
        setTimeout(() => {
          document.querySelector(".port-nav").style.setProperty("display", "none");
        }, 500);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.navbar {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  transform: translate3d(0, 0, 0);
  transition: 500ms all ease-in-out;
  box-shadow: 0 0 10px 0 var(--banner-colour);
  z-index: 10000;

  .nav-title {
    a {
      display: flex;
      align-items: flex-start;
      // margin-left: 2rem;
      color: var(--text-colour);
      svg {
        width: 10rem;
        margin-right: 15rem;
      }
      .cls-1 {
        fill: var(--text-colour);
      }
    }
  }

  .nav-menu {
    height: 10vh;
    // margin-right: 1rem;
    a {
      position: relative;
      top: 15%;
      margin: 1rem 1rem;
      padding: 1rem 1rem;
      border-radius: 50px;
      text-decoration: none;
      color: var(--text-colour);
      font-size: 1.2rem;
      font-weight: 400;
      font-style: normal;
      transition: all 1000ms ease-in-out;
      font-family: "Bebas Neue", sans-serif;
      text-transform: uppercase;
      &:hover {
        color: var(--alt-text-colour);
      }
      &.active {
        text-decoration: underline;
      }
    }
  }

  .nav-button {
    // margin-right: 2rem;
    a {
      display: flex;
      flex-direction: column;
      text-decoration: none;
      color: var(--alt-text-colour);
      font-size: 1.2rem;
      font-weight: 500;
      margin: 1rem 1rem;
      &:hover {
        color: var(--text-colour);
        text-decoration: underline;
      }
    }
  }

  .hamburger {
    display: none;
  }
  .port-nav {
    display: none;
  }
}
.navbar.navbar-hidden {
  transform: translate3d(0, -100%, 0);
}

@media only screen and (orientation: portrait) {
  .navbar {
    display: flex;
    flex-direction: row;
    padding: 1.5rem 0rem;
    position: fixed;
    .nav-title {
      a {
        svg {
          padding: 0.5rem;
          width: 6rem;
          margin-right: 0rem;
        }
      }
    }
    .nav-button {
      display: none;
    }
    .hamburger {
      display: block;
      margin-right: 2rem;
      .ham-icon {
        font-size: 2rem;
        color: var(--alt-text-colour);
      }
    }
    .port-nav {
      display: none;
      height: 100vh;
      width: 100%;
      z-index: 100;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      padding: 3rem 0.5rem;
      right: 0;
      top: 0;
      transform: translateX(500px);
      transition: all 500ms ease-in-out;
      position: fixed;
      background-color: var(--background-colour);
      
      a,
      button {
        all: unset;
        text-decoration: none;
        text-align: right;
        background-color: var(--primary-colour);
        color: var(--text-colour);
        width: 19rem;
        padding: 2rem;
        margin: 0.5rem 0;
        box-shadow: var(--box-shadow);
        border-radius: 5px;
        z-index: 100;
        &:hover {
          color: var(--action-colour);
        }
      }
    }
  }
  .nav-menu {
    display: none;
  }
}
</style>
