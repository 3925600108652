<template>
  <div class="content">
    <div class="links">
      <router-link to="/privacyPolicy">Privacy Policy</router-link>
      <p>|</p>
      <router-link to="/termsOfService">Terms of service</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterItem",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.content {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 0.25rem;
  z-index: 10000;
  .links {
    display: flex;
    align-items: left;
    justify-content: center;
    p {
      margin: 0;
      color: var(--alt-text-colour);
      opacity: 0.5;
    }
    a {
      text-decoration: none;
      color: var(--alt-text-colour);
      padding: 0.25rem;
      transition: all 500ms ease-in-out;
      opacity: 0.5;
    }
    :hover {
      scale: 1.1;
      cursor: pointer;
    }
  }
}

@media only screen and (orientation: portrait) {
}
</style>
