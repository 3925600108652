import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../components/Home.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Beamy",
      description: "Beamy your home for all things POS",
    },
  },  
  {
    path: "/About",
    name: "AboutItem",
    component: () => import("../components/About.vue"),
    meta: {
      title: "About",
      description: "About Beamy",
    },
  },
  {
    path: "/pricing",
    name: "pricingItem",
    component: () => import("../components/Pricing.vue"),
    meta: {
      title: "Pricing & Packages",
      description: "Pricing & Packages",
    },
  },
  {
    path: "/privacyPolicy",
    name: "privacyPolicyItem",
    component: () => import("../views/PrivacyPolicy.vue"),
    meta: {
      title: "Privacy policy",
      description: "Privacy policy",
    },
  },
  {
    path: "/termsOfService",
    name: "termsOfServiceItem",
    component: () => import("../views/TermsOfService.vue"),
    meta: {
      title: "Terms of service",
      description: "Terms of service",
    },
  },
  
];



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "Default Title";
  next();
});

export default router;
