<template>
  <section class="left-panel">
    <router-link to="/">
      <img src="../../public/Beamy_helmet.svg" alt="beamy helemt" class="icon helmet" />
    </router-link>
    <a href="https://www.instagram.com" target="_blank"><font-awesome-icon :icon="['fab', 'instagram']" size="2x" class="icon" /></a>
    <a href="https://www.facebook.com" target="_blank"><font-awesome-icon :icon="['fab', 'facebook']" size="2x" class="icon" /> </a>
    <div class="email">
      <a href="mailto:sales@beamy.nz"><font-awesome-icon :icon="['fa', 'envelope']" size="2x" class="icon" /> </a>
      <span class="hide">sales@beamy.nz</span>
    </div>
  </section>
</template>

<script>
export default {
  name: "SocialIcons",
  data() {
    return {
      // Add your social icons data here
    };
  },
  methods: {
    // Add your methods here
  },
};
</script>

<style>
.hide {
  transform: translateX(-150%);
  transition: transform 500ms ease-in-out;
}

.email:hover .hide {
  transform: translateX(0);
}

.email {
  display: flex;
  align-items: center;
}

.left-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 5rem;
  position: fixed;
  top: 40%;
  z-index: 1000;
  .icon {
    height: 1.5rem;
    width: 1.5rem;
    color: var(--text-colour);
  }
  a {
    all: unset;
    padding: 0.75rem;
    transition: all 500ms ease-in-out;
    margin-left: 0.5rem;
  }
  a:hover {
    cursor: pointer;
    transform: scale(1.5);
  }
}

</style>
