import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPhone,
  faEdit,
  faPlus,
  faSave,
  faTrash,
  faBars,
  faSort,
  faMinusCircle,
  faPlusCircle,
  faChevronDown,
  faChevronUp,
  faGripHorizontal,
  faGripLines,
  faXmark,
  faSquareCaretDown,
  faPencil,
  faGear,
  faCamera,
  faBackward,
  faCodeCompare,
  faShoppingCart,
  faShop,
  faPenToSquare,
  faMoneyBillTrendUp,
  faTag,
  faRightFromBracket,
  faPlugCirclePlus,
  faMoneyCheckDollar,
  faFileInvoiceDollar,
  faUser,
  faUserAstronaut,
  faGift,
  faEnvelope,
  faCalendarDays,
  faArrowRight,
  faArrowLeft,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
library.add(
  faFacebook,
  faInstagram,
  faPhone,
  faEdit,
  faPlus,
  faSave,
  faTrash,
  faBars,
  faSort,
  faMinusCircle,
  faPlusCircle,
  faChevronDown,
  faChevronUp,
  faGripHorizontal,
  faGripLines,
  faXmark,
  faSquareCaretDown,
  faPencil,
  faGear,
  faCamera,
  faBackward,
  faCodeCompare,
  faShoppingCart,
  faShop,
  faPenToSquare,
  faMoneyBillTrendUp,
  faTag,
  faRightFromBracket,
  faPlugCirclePlus,
  faMoneyCheckDollar,
  faFileInvoiceDollar,
  faUser,
  faUserAstronaut,
  faGift,
  faEnvelope,
  faCalendarDays,
  faArrowRight,
  faArrowLeft,
  faCircleCheck,
);

createApp(App).use(router).component("font-awesome-icon", FontAwesomeIcon).mount("#app");
